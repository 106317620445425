import { Link } from 'gatsby'
import React from 'react'

import './header.scss'
import imageFace from '../images/face.jpg'

const Header = ({ siteTitle }) => (
  <div className="header">
    <div>
      <img alt="Michel Westrate" src={imageFace} />
      <Link
        to="/"
        style={{
          color: `white`,
          textDecoration: `none`,
        }}
        className="title"
      >
        michel.codes
      </Link>
      <br />
      <MenuLink title="About" href="/" />
      <MenuLink title="Workshops" href="workshops" />
      <MenuLink title="Talks" href="talks" />
      <MenuLink title="Blogs" href="blogs" />
    </div>
  </div>
)

const MenuLink = ({ title, href }) => (
  <Link activeClassName="activeLink" to={href}>
    {title}
  </Link>
)

export default Header
