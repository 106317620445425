import React from 'react'

import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <img src={require("../images/nv.gif")}/>
  </Layout>
)

export default NotFoundPage
