import React from 'react'
import PropTypes from 'prop-types'

import Header from './header'
import './layout.scss'
import './theme.scss'

const Layout = ({ children }) => (
  <>
    <Header />
    <div className="content"   >
      {children}
      <footer><center>
        <hr/>
        © 2019, Michel Weststrate, built with <a href="https://www.gatsbyjs.org">Gatsby</a>
        {" | "} 
        <a href="mailto:info@michel.codes">Contact</a>
      </center>
      </footer>
    </div>
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
